@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/magnific-popup/src/css/main";

html {
	background: $color-white;
	background-image: url('../img/bg/bg-mid.png');
	background-position: center;
	background-size: auto 100%;
	font-family: 'Public Sans', sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 1;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $color-blue;
}

a {
	color: $color-blue;

	@include hover-focus {
		color: $color-blue;
	}
}

#content {
	color: $color-blue;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	min-height: 100vh;
}

header {
	flex: 0 0 auto;
}

main {
	align-items: center;
	display: flex;
	flex: 1 0 auto;

	.container {
		padding: 0 30px;
	}

	.inside {
		align-items: center;
		background: $color-gray;
		border-radius: 20px;
		box-shadow: 0 0 rem(20) #AAAAAAAA;
		display: flex;
		flex-direction: column;
		height: 300px;
		justify-content: space-between;
		margin: 0 auto;
		padding: 9px 15px 0 15px;
		text-align: center;
		width: 466px;
		max-width: 100%;
		&::after{
			content:'';
		}

		.stripe{
			height: 10px;
			margin-top: rem(15);
			background-color: $color-red;
			width: 100%;
		}

		@include phone-down {
			height: 330px;
			padding: 4px 15px 0 15px;
			width: 315px;
		}
		p {
			font-size: rem(26);
			text-transform: uppercase;
			font-weight: 900;
			a{
				text-decoration: underline;
				@include hover-focus{
					color: $color-red;
				}
			}
		}

		.btn {
			min-width: 240px;
		}

		.disclaimer {
			font-size: 8px;
			margin-top: 10px;
			text-transform: uppercase;
		}
	}
}

footer {
	flex: 0 0 auto;
	padding-bottom: 10px;

	.inside {
		background: $color-gray;
		box-shadow: 0 0 rem(20) #AAAAAAAA;
		border-radius: 20px;
		margin: 0 auto;
		max-width: 466px;
		padding: 10px;
		text-align: center;

		> .socials {
			list-style: none;
			margin: 0 0 10px 0;
			padding: 0;

			> li {
				display: inline-block;
				font-size: 20px;
				vertical-align: middle;

				+ li {
					margin-left: 10px;
				}

				> a {
					color: $color-red;

					@include hover-focus {
						color: $color-blue;
					}
				}
			}
		}

		> .copyright {
			p {
				font-size: 8px;
				letter-spacing: 0.08px;
				line-height: 8px;
				margin: 0;
			}
		}
	}
}

.btn {
	background: $color-red;
	border: 0;
	border-radius: 20px;
	color: $color-white;
	display: inline-block;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 35px;
	min-height: 35px;
	padding: 2px 30px;
	text-align: center;
	text-transform: uppercase;
	vertical-align: middle;

	> span {
		display: inline-block;
		margin-right: 5px;
		vertical-align: middle;
	}

	> img {
		display: inline-block;
		vertical-align: middle;
		width: 83px;
	}

	&.btn-apple {
		> span {
			margin-top: 2px;
		}
	}

	> svg {
		display: inline-block;
		height: 19px;
		margin-top: -1px;
		vertical-align: middle;
		width: auto;

		path {
			fill: $color-white;
		}
	}

	@include hover-focus {
		background: $color-blue;
		color: $color-white;
	}

	+ .btn {
		margin-top: 10px;
	}
}

.popup {
	background: $color-gray;
	border-radius: 20px;
	margin: 20px auto;
	max-width: 500px;
	padding: 20px;
	position: relative;
	text-align: center;
	text-transform: uppercase;
	width: auto;

	form {
		label {
			display: block;
		}

		input {
			background: none;
			border: 1px solid $color-blue;
			color: $color-blue;
			display: block;
			line-height: 1;
			margin: 15px 0;
			padding: 5px;
			width: 100%;

			@include placeholder {
				color: $color-blue;
			}

			&:focus {
				border-color: $color-red;
				outline: 0;
			}
		}
	}
}
